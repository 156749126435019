import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { ArrowRightIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Fragment, useEffect, useRef, useState } from 'react'
import DateTimePicker from './DateTimePicker'
import RelativeTimePeriod from './RelativeTimePeriod'

dayjs.extend(duration)
dayjs.extend(relativeTime)

enum TimeRangeType {
    Absolute,
    Relative,
}

// We could include timezone here by adding `Z[Z]` but we assume the user
// will expect to see their own timezone, which they will.
const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'
const DEFAULT_DURATION = 'P7D'

type Props = {
    startDateTime?: Dayjs
    endDateTime?: Dayjs
    timeDuration?: Duration
    // We need to set everything together otherwise the URL and the search hook will get
    // confused. I faffed around a lot to get here so don't even think of changing this.
    onDateChange: (start?: Dayjs, end?: Dayjs, duration?: Duration) => void
}

function getDropdownTextForAbsolute(start: Dayjs, end: Dayjs) {
    return (
        <span className="flex flex-row">
            {start.format(DATETIME_FORMAT)}
            &nbsp;
            <ArrowRightIcon className="mx-2 h-5 w-5 text-gray-800" />
            &nbsp;
            {end.format(DATETIME_FORMAT)}
        </span>
    )
}

function getDropdownTextForRelative(duration: Duration) {
    return <span>{duration.humanize()}</span>
}

function DateTimePickerContainer(props: Props) {
    const { startDateTime, endDateTime, timeDuration, onDateChange } = props

    // We need to track which tab is selected...
    const [activeTab, setActiveTab] = useState(
        startDateTime && endDateTime ? TimeRangeType.Absolute : TimeRangeType.Relative
    )

    // ... and the text for the dropdown. This is a ReactNode because for absolute
    // we need an arrow, and that needs HTML not just a string.
    const [dropdownText, setDropdownText] = useState<React.ReactNode>(<></>)

    // We use local states for start/end because we want to 'apply' any date changes togetgher
    const [localStart, setLocalStart] = useState<Dayjs | undefined>(startDateTime)
    const [localEnd, setLocalEnd] = useState<Dayjs | undefined>(endDateTime)

    // We'll only use these intital values once, so we don't want them forcing any re-renders.
    // Hence useRef over useState.
    const initStartRef = useRef(startDateTime)
    const initEndRef = useRef(endDateTime)
    const initDurRef = useRef(timeDuration)

    // Represent the tabs for Absolute and Relative time ranges, including copy.
    const tabs = [
        {
            name: 'Absolute',
            type: TimeRangeType.Absolute,
            current: activeTab === TimeRangeType.Absolute,
        },
        {
            name: 'Relative',
            type: TimeRangeType.Relative,
            current: activeTab === TimeRangeType.Relative,
        },
    ]

    // Initialisation using the refs
    useEffect(() => {
        applyTabSettings(activeTab, initStartRef.current, initEndRef.current)
        // Disable linting because no deps = on mount function.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // This `useEffect` detects when there's no incoming time range. In this case we have to
    // set some default -- that's what this is.
    useEffect(() => {
        if (!startDateTime && !endDateTime && !timeDuration) {
            const defaultDuration = dayjs.duration(DEFAULT_DURATION)
            onDateChange(undefined, undefined, defaultDuration)
        }
    }, [startDateTime, endDateTime, timeDuration, onDateChange])

    // Handle all changes to tabs, including initialisation
    const applyTabSettings = (tab: TimeRangeType, localStart?: Dayjs, localEnd?: Dayjs) => {
        if (tab === TimeRangeType.Absolute) {
            // Set defaults if not available
            if (!localStart) setLocalStart(dayjs().subtract(7, 'day'))
            if (!localEnd) setLocalEnd(dayjs())

            // Set the text -- we need the test here
            if (localStart && localEnd) {
                setDropdownText(getDropdownTextForAbsolute(localStart, localEnd))
            }
            // Don’t call onDateChange for absolute until "Apply"
        } else {
            // For relative, always set it immediately
            const effective = initDurRef.current ?? dayjs.duration(DEFAULT_DURATION)
            setDropdownText(getDropdownTextForRelative(effective))
            onDateChange(undefined, undefined, effective)
        }
    }

    const handleTabClick = (newTab: TimeRangeType) => {
        setActiveTab(newTab)
        applyTabSettings(newTab, localStart, localEnd)
    }

    // Other than intiialisation in the above useEffects these two handling functions are the only
    // place onDateChange gets called. You can see it's either two dates:
    //     onDateChange(localStart, localEnd, undefined)
    // or a duration:
    //     onDateChange(undefined, undefined, localDuration)
    // We also check for undefined. This all means no invalid combination ever escapes this component.
    const handleApplyAbsolute = () => {
        if (localStart && localEnd) {
            setDropdownText(getDropdownTextForAbsolute(localStart, localEnd))
            onDateChange(localStart, localEnd, undefined)
        }
    }
    const handleApplyRelative = (duration: Duration) => {
        setDropdownText(getDropdownTextForRelative(duration))
        onDateChange(undefined, undefined, duration)
    }

    return (
        <>
            <div className="rounded-md shadow">
                <Popover className="relative">
                    {({ open, close }) => (
                        <>
                            <PopoverButton className="inline-flex items-center gap-x-1 text-sm leading-6 text-gray-950">
                                <span className="relative inline-flex items-center space-x-2 px-4 py-2 text-sm rounded-md text-gray-950 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary">
                                    {dropdownText}
                                    <ChevronDownIcon
                                        className={open ? 'rotate-180 transform h-5 w-5' : 'h-5 w-5'}
                                        aria-hidden="true"
                                    />
                                </span>
                            </PopoverButton>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <PopoverPanel className="absolute left-56 z-30 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4 ">
                                    <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                                        <div className="hidden sm:block">
                                            <div className="p-2">
                                                {tabs.map((tab) => (
                                                    <button
                                                        key={tab.name}
                                                        onClick={() => handleTabClick(tab.type)}
                                                        className={clsx(
                                                            tab.current
                                                                ? 'bg-gray-200 text-gray-950'
                                                                : 'text-gray-800 hover:text-gray-700',
                                                            'rounded-md px-3 py-2 text-sm font-medium'
                                                        )}
                                                    >
                                                        {tab.name}
                                                    </button>
                                                ))}

                                                {activeTab === TimeRangeType.Absolute ? (
                                                    <button
                                                        onClick={() => {
                                                            close()
                                                            handleApplyAbsolute()
                                                        }}
                                                        className="ml-40 inline-flex items-center rounded-md bg-primary px-2 py-1 text-sm font-semibold text-white shadow hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                                    >
                                                        Apply
                                                    </button>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                        <div className="pl-2 pr-2 pb-2">
                                            <div className="m-1">
                                                {activeTab === TimeRangeType.Absolute ? (
                                                    <div className="flex flex-col gap-4">
                                                        <div className="flex flex-row items-center gap-2">
                                                            <label
                                                                htmlFor="startDateTimePicker"
                                                                className="text-gray-800 font-semibold w-10"
                                                            >
                                                                From
                                                            </label>
                                                            <div className="w-80">
                                                                <DateTimePicker
                                                                    id="startDateTimePicker"
                                                                    dateTime={localStart}
                                                                    onChange={setLocalStart}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center gap-2">
                                                            <label
                                                                htmlFor="endDateTimePicker"
                                                                className="text-gray-800 font-semibold w-10"
                                                            >
                                                                To
                                                            </label>

                                                            <div className="w-80">
                                                                <DateTimePicker
                                                                    id="endDateTimePicker"
                                                                    dateTime={localEnd}
                                                                    onChange={setLocalEnd}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <RelativeTimePeriod
                                                            duration={timeDuration}
                                                            onChange={(duration) => {
                                                                close()
                                                                handleApplyRelative(duration)
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </PopoverPanel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
        </>
    )
}

export default DateTimePickerContainer
