import dayjs from 'dayjs'
import { SpamSearchResult } from '../../../api/client'
import ContentList from '../../../components/content/ContentList'
import RemoveSpamModal from '../../../components/spam/RemoveSpamModal'
import useContentSearchParams from '../../../hooks/useContentQueryParams'

type Props = {
    spamIds: number[]
    scores: SpamSearchResult[]
}

export function SpamContentList(props: Props) {
    const { spamIds, scores } = props
    const { searchParameters } = useContentSearchParams()

    searchParameters.contentIds = spamIds
    searchParameters.owned = undefined
    // This is a hack and overrides whatever value might be in the URL - which is nothing as the search content bar is not visible.
    // All in aall a bit hacky.
    searchParameters.startDateTime = dayjs('2019-01-01')
    searchParameters.endDateTime = dayjs('2019-01-02')

    return (
        <div className="self-center pt-8">
            <RemoveSpamModal contentIds={spamIds} />
            <ContentList
                searchParameters={searchParameters}
                showOwningAccount={true}
                enableBulkActions={true}
                scores={scores}
            />
        </div>
    )
}
