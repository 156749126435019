import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFetchHistoricContentMutation } from '../api/client'

const DEFAULT_TWEETS_REQUESTED = 1000

function FetchHistoricContentPage() {
    let { accountId: accountIdString } = useParams()
    const accountId = Number(accountIdString)
    const [tweetsRequested, setTweetsRequested] = useState(
        DEFAULT_TWEETS_REQUESTED
    )

    const [requestSent, setRequestSent] = useState(false)

    const [fetchHistoricContent] = useFetchHistoricContentMutation()
    const onFetchHistoricContent = async () => {
        await fetchHistoricContent({
            variables: {
                accountId,
                tweetsRequested,
            },
        })
        setRequestSent(true)
    }

    function handleChange(event: React.FormEvent<HTMLInputElement>) {
        const newKeyword = event.currentTarget.value
        setTweetsRequested(Number(newKeyword))
    }

    return (
        <div className="p-12 bg-white h-screen">
            <article className="prose lg:prose-l">
                <h1>Fetch Historic Content</h1>
                <Link to={`/account/${accountId}/settings`}>
                    Back to settings
                </Link>
                <p>
                    This is an admin only page.{' '}
                    <a
                        href="https://www.notion.so/HOWTO-Fetch-historic-content-using-the-app-c966d1441a6940c19f814de268a6088e"
                        target="_blank"
                        rel="noreferrer"
                    >
                        For instructions on using this page see here
                    </a>
                </p>

                <label htmlFor="email" className="block text-sm  text-gray-700">
                    Number of historic comments to fetch
                </label>
                <div className="mt-1 flex">
                    <input
                        value={tweetsRequested}
                        type="number"
                        name="number"
                        step={500}
                        onChange={handleChange}
                        className="shadow-sm focus:ring-primary-500 focus:border-primary block border-gray-300 rounded-md"
                    />
                    {!requestSent ? (
                        <button
                            type="button"
                            onClick={async () => await onFetchHistoricContent()}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow px-4 py-2 bg-red-600 text-base  text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Fetch historic content
                        </button>
                    ) : null}
                    {requestSent ? (
                        <div className="pl-4 pt-2">Request sent</div>
                    ) : null}
                </div>
            </article>
        </div>
    )
}

export default FetchHistoricContentPage
