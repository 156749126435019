/**
 * List of all the possible query parameters used by Arwen.
 */

// Absolute date period search params
export const PERIOD_ABS_START_QUERY_PARAM = 'start'
export const PERIOD_ABS_END_QUERY_PARAM = 'end'
// Relative date period search params
export const PERIOD_REL_DURATION_QUERY_PARAM = 'duration'

export const ACCOUNTS_QUERY_PARAM = 'profile'
export const TEAMS_QUERY_PARAM = 'teams'
export const SOCIAL_MEDIA_SERVICE_QUERY_PARAM = 'sms'
export const KEYWORD_QUERY_PARAM = 'keyword'
export const MODERATION_QUERY_PARAM = 'moderation'
export const EMOTIONS_QUERY_PARAM = 'emotions'
export const GENERAL_CLASSIFIER_QUERY_PARAM = 'gclass'
export const TOPICS_QUERY_PARAM = 'topic'
export const SUGGESTED_REPLIES = 'sgreplies'
export const REPLIES = 'replies'
export const ADS_OR_ORGANIC_QUERY_PARAM = 'adOrOrganic'
export const READ_RECEIPT_QUERY_PARAM = 'readReceipt'
export const OFFSET_QUERY_PARAM = 'off'
export const LIMIT_QUERY_PARAM = 'lim'

export const decodeIdsQueryParam = (accountIdsQueryParam: string): number[] => {
    if (!!accountIdsQueryParam && accountIdsQueryParam.length !== 0) {
        return accountIdsQueryParam.split(',').map((id) => parseInt(id))
    } else {
        return []
    }
}

export const encodeIdsQueryParam = (ids: number[]): string => {
    return ids.join(',')
}

export const decodeStringsQueryParam = (strings1: string): string[] => {
    if (!!strings1 && strings1.length !== 0) {
        return strings1.split(',')
    } else {
        return []
    }
}
export const encodeStringQueryParam = (strings: string[]): string => {
    return strings.join(',')
}
