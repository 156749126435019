import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import {
    Maybe,
    SocialMediaService,
    useGetSummaryForAccountsQuery,
} from '../api/client'
import { ClassifierSearchParam } from '../models/ClassifierSearchParam'

dayjs.extend(duration)

// This is suboptimal but if the class name is not explicitly written somewhere in the code the styling will get purged by tailwind.
const getTextColour = (severity: string) => {
    if (severity === 'severe') {
        return 'text-severe'
    } else if (severity === 'suspect') {
        return 'text-suspect'
    } else if (severity === 'safe') {
        return 'text-safe'
    } else {
        return 'text-gray-500'
    }
}

type Props = {
    onModerationChange: (moderation: string) => void
    startDateTime?: Dayjs
    endDateTime?: Dayjs
    timeDuration?: Duration
    offsetDuration?: string
    selectedModeration?: string
    accountIds: number[]
    teamIds: number[]
    selectedSocialMediaServices: Array<SocialMediaService>
    keyword: string
    selectedClassifiers: ClassifierSearchParam[]
}

type ModerationView = {
    id: string
    display: string
    total?: Maybe<number>
}

function SummaryStatistics(props: Props) {
    const {
        onModerationChange,
        startDateTime,
        endDateTime,
        selectedModeration,
        accountIds,
        teamIds,
        selectedSocialMediaServices,
        keyword,
        selectedClassifiers,
    } = props

    const timeDuration = props.timeDuration
        ? `${props.timeDuration.toISOString()}`
        : undefined

    const { data, loading } = useGetSummaryForAccountsQuery({
        variables: {
            params: {
                accountIds,
                teamIds,
                fromDatePosted: startDateTime,
                toDatePosted: endDateTime,
                timeDuration,
                socialMediaServiceIds: selectedSocialMediaServices.map(
                    (service) => service.id
                ),
                keyword,
                classifierSearchParams: selectedClassifiers,
            },
        },
    })

    const moderations: ModerationView[] = [
        {
            id: 'all',
            display: 'All',
        },
        {
            id: 'safe',
            display: 'Safe',
            total: data?.getSummaryForAccounts?.countSafe,
        },
        {
            id: 'suspect',
            display: 'Suspect',
            total: data?.getSummaryForAccounts?.countSuspect,
        },
        {
            id: 'severe',
            display: 'Severe',
            total: data?.getSummaryForAccounts?.countSevere,
        },
    ]

    function handleModerationChange(moderationView: ModerationView) {
        const moderation = moderationView.display.toLowerCase()
        onModerationChange && onModerationChange(moderation)
    }

    return (
        <div>
            {loading && (
                <div className="flex animate-pulse flex-row items-stretch content-center h-full w-full justify-center ">
                    <div className="flex flex-row mt-1 ">
                        <div className="mr-1 w-16 bg-gray-300 h-8 rounded-md "></div>
                        <div className="mr-1 w-16 bg-gray-300 h-8 rounded-md "></div>
                        <div className="mr-1 w-16 bg-gray-300 h-8 rounded-md "></div>
                        <div className="w-16 bg-gray-300 h-8 rounded-md "></div>
                    </div>
                </div>
            )}
            {!loading && (
                <span className="relative z-0 inline-flex shadow rounded-md">
                    {moderations.map((item, index) => {
                        let roundedClasses = ''
                        if (index === 0) {
                            roundedClasses = 'rounded-l-md'
                        } else if (index === moderations.length - 1) {
                            roundedClasses = 'rounded-r-md'
                        }

                        let classes =
                            'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm text-gray-950 hover:bg-gray-100 hover:border-gray-400 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                        if (
                            selectedModeration?.toLowerCase() ===
                            item.display?.toLowerCase()
                        ) {
                            classes =
                                'relative inline-flex items-center px-4 py-2 bg-gray-200  border border-gray-300 text-sm hover:bg-gray-100 hover:border-gray-400 text-gray-950  focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                        }
                        return (
                            <div key={item.display}>
                                <button
                                    type="button"
                                    onClick={() => handleModerationChange(item)}
                                    className={`${classes} ${roundedClasses}`}
                                    data-cy={`${item.display}-classification-button`}
                                >
                                    {item.display}
                                    {item.total !== null &&
                                    item.total !== undefined &&
                                    item.total >= 0 ? (
                                        <span
                                            className={`ml-2  ${getTextColour(
                                                item.display.toLowerCase()
                                            )}`}
                                        >
                                            ({item.total})
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                </button>
                            </div>
                        )
                    })}
                </span>
            )}
        </div>
    )
}

export default SummaryStatistics
