import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import { useSearchParams } from 'react-router-dom'
import { AdOrOrganicFilterOption } from '../components/AdOrOrganicComboBox'
import { SearchParameters } from '../components/content/search/ContentSearchBar'
import { getClassifierSearchParam } from '../models/ClassifierSearchParam'
import { ReadFilterView, SocialMediaService } from '../api/client'
import { useEffect, useState } from 'react'
import {
    ACCOUNTS_QUERY_PARAM,
    ADS_OR_ORGANIC_QUERY_PARAM,
    EMOTIONS_QUERY_PARAM,
    GENERAL_CLASSIFIER_QUERY_PARAM,
    KEYWORD_QUERY_PARAM,
    MODERATION_QUERY_PARAM,
    PERIOD_ABS_END_QUERY_PARAM,
    PERIOD_ABS_START_QUERY_PARAM,
    PERIOD_REL_DURATION_QUERY_PARAM,
    READ_RECEIPT_QUERY_PARAM,
    REPLIES,
    SOCIAL_MEDIA_SERVICE_QUERY_PARAM,
    SUGGESTED_REPLIES,
    TEAMS_QUERY_PARAM,
    TOPICS_QUERY_PARAM,
    decodeIdsQueryParam,
    decodeStringsQueryParam,
    encodeIdsQueryParam,
    encodeStringQueryParam,
} from '../models/QueryParameters'
import { EMOTION_THRESHOLD, TOPICS_THRESHOLD } from '../pages/IntelligencePage'

dayjs.extend(duration)

export default function useContentSearchParams(): {
    searchParameters: SearchParameters
    onSearchParameterChange: (searchParameters: SearchParameters) => void
    allSocialMediaServices: Array<SocialMediaService>

    setAllSocialMediaServices: (socialMediaServices: Array<SocialMediaService>) => void
} {
    const [searchParams, setSearchParams] = useSearchParams()

    const [allSocialMediaServices, setAllSocialMediaServices] = useState<Array<SocialMediaService>>([])

    //
    // Extract the query parameters from the URL
    //
    const accountIdsQueryString = searchParams.get(ACCOUNTS_QUERY_PARAM)
    const accountIds = decodeIdsQueryParam(accountIdsQueryString || '')

    const teamIdsQueryString = searchParams.get(TEAMS_QUERY_PARAM)
    const teamIds = decodeIdsQueryParam(teamIdsQueryString || '')

    const socialMediaServiceIdsQueryString = searchParams.get(SOCIAL_MEDIA_SERVICE_QUERY_PARAM)
    const socialMediaServiceIds = decodeIdsQueryParam(socialMediaServiceIdsQueryString || '')

    // Relative date period search params
    const timeDurationString = searchParams.get(PERIOD_REL_DURATION_QUERY_PARAM)
    const startDateTimeString = searchParams.get(PERIOD_ABS_START_QUERY_PARAM)
    const endDateTimeString = searchParams.get(PERIOD_ABS_END_QUERY_PARAM)

    const [timeDuration, setTimeDuration] = useState<Duration | undefined>(
        timeDurationString ? dayjs.duration(timeDurationString) : undefined
    )
    useEffect(() => {
        if (timeDurationString) {
            setTimeDuration(dayjs.duration(timeDurationString))
        } else setTimeDuration(undefined)
    }, [timeDurationString])

    // Absolute date period search params
    const [startDateTime, setStartDateTime] = useState<Dayjs | undefined>(
        startDateTimeString ? dayjs(startDateTimeString) : undefined
    )
    const [endDateTime, setEndDateTime] = useState<Dayjs | undefined>(
        endDateTimeString ? dayjs(endDateTimeString) : undefined
    )
    useEffect(() => {
        if (startDateTimeString) {
            setStartDateTime(dayjs(startDateTimeString))
        } else {
            setStartDateTime(undefined)
        }

        if (endDateTimeString) {
            setEndDateTime(dayjs(endDateTimeString))
        } else {
            setEndDateTime(undefined)
        }
    }, [startDateTimeString, endDateTimeString])

    const keyword = searchParams.get(KEYWORD_QUERY_PARAM) || ''
    const moderation = searchParams.get(MODERATION_QUERY_PARAM) || 'all'

    // General classifiers
    const generalClassifierQueryString = searchParams.get(GENERAL_CLASSIFIER_QUERY_PARAM)
    const selectedGeneralClassifiers = decodeStringsQueryParam(generalClassifierQueryString || '')

    // Emotion classifiers
    const emotionsQueryString = searchParams.get(EMOTIONS_QUERY_PARAM)
    const selectedEmotions = decodeStringsQueryParam(emotionsQueryString || '')

    // Topic classifiers
    const topicsQueryString = searchParams.get(TOPICS_QUERY_PARAM)
    const selectedTopics = decodeStringsQueryParam(topicsQueryString || '')

    // Replies query parameters
    const suggestedReplies = searchParams.get(SUGGESTED_REPLIES) || ''
    const replies = searchParams.get(REPLIES) || ''

    // Ads or Organic query params
    const adOrOrganic = (searchParams.get(ADS_OR_ORGANIC_QUERY_PARAM) as AdOrOrganicFilterOption) || undefined

    // Read/Unread query param
    const readReceipt = (searchParams.get(READ_RECEIPT_QUERY_PARAM) as ReadFilterView) || undefined

    // Update all parameters at once. Here, params are coming from changes to the
    // page, not changes to the query params.
    function handleParameterChange(searchParameters: SearchParameters) {
        const newSearchParams = new URLSearchParams(searchParams.toString())

        if (searchParameters.selectedGeneralClassifiers.length > 0) {
            newSearchParams.set(
                GENERAL_CLASSIFIER_QUERY_PARAM,
                encodeStringQueryParam(searchParameters.selectedGeneralClassifiers)
            )
        } else {
            newSearchParams.delete(GENERAL_CLASSIFIER_QUERY_PARAM)
        }

        if (searchParameters.selectedTopics.length > 0) {
            newSearchParams.set(TOPICS_QUERY_PARAM, encodeStringQueryParam(searchParameters.selectedTopics))
        } else {
            newSearchParams.delete(TOPICS_QUERY_PARAM)
        }

        if (searchParameters.selectedEmotions.length > 0) {
            newSearchParams.set(
                EMOTIONS_QUERY_PARAM,
                encodeStringQueryParam(searchParameters.selectedEmotions)
            )
        } else {
            newSearchParams.delete(EMOTIONS_QUERY_PARAM)
        }

        // Time range search parameters ------------------------------------
        // timeDuration, startDateTime, and endDateTime are all related.
        // You can have either: timeDuration OR (startDateTime AND endDateTime),
        // so if one is set then the other(s) need to be unset.
        //
        // Notice that there's some precdence here: if startDateTime and endDateTime are set,
        // then timeDuration will be unset. This shouldn't be an issue because
        // DateTimePickerContainer.tsx handles all the setting of these values.
        // Ff anything weird every starts happening you should probably fix it there.
        if (searchParameters.timeDuration) {
            newSearchParams.set(PERIOD_REL_DURATION_QUERY_PARAM, searchParameters.timeDuration.toISOString())
            newSearchParams.delete(PERIOD_ABS_START_QUERY_PARAM)
            newSearchParams.delete(PERIOD_ABS_END_QUERY_PARAM)
        } else {
            newSearchParams.delete(PERIOD_REL_DURATION_QUERY_PARAM)
        }
        if (searchParameters.startDateTime) {
            newSearchParams.set(
                PERIOD_ABS_START_QUERY_PARAM,
                String(searchParameters.startDateTime.toISOString())
            )
            newSearchParams.delete(PERIOD_REL_DURATION_QUERY_PARAM)
        } else {
            newSearchParams.delete(PERIOD_ABS_START_QUERY_PARAM)
        }
        if (searchParameters.endDateTime) {
            newSearchParams.set(
                PERIOD_ABS_END_QUERY_PARAM,
                String(searchParameters.endDateTime.toISOString())
            )
            newSearchParams.delete(PERIOD_REL_DURATION_QUERY_PARAM)
        } else {
            newSearchParams.delete(PERIOD_ABS_END_QUERY_PARAM)
        }
        // END time range search parameters ------------------------------------

        if (searchParameters.selectedSocialMediaServices) {
            newSearchParams.set(
                SOCIAL_MEDIA_SERVICE_QUERY_PARAM,
                encodeIdsQueryParam(searchParameters.selectedSocialMediaServices.map((service) => service.id))
            )
        } else {
            newSearchParams.delete(SOCIAL_MEDIA_SERVICE_QUERY_PARAM)
        }

        if (searchParameters.selectedTeams.length > 0) {
            newSearchParams.set(TEAMS_QUERY_PARAM, encodeIdsQueryParam(searchParameters.selectedTeams))
        } else {
            newSearchParams.delete(TEAMS_QUERY_PARAM)
        }

        if (searchParameters.selectedAccountIds.length > 0) {
            newSearchParams.set(
                ACCOUNTS_QUERY_PARAM,
                encodeIdsQueryParam(searchParameters.selectedAccountIds)
            )
        } else {
            newSearchParams.delete(ACCOUNTS_QUERY_PARAM)
        }

        if (searchParameters.moderation.length > 0) {
            newSearchParams.set(MODERATION_QUERY_PARAM, searchParameters.moderation)
        } else {
            newSearchParams.delete(MODERATION_QUERY_PARAM)
        }

        if (searchParameters.keyword.length > 0) {
            newSearchParams.set(KEYWORD_QUERY_PARAM, searchParameters.keyword)
        } else {
            newSearchParams.delete(KEYWORD_QUERY_PARAM)
        }

        if (searchParameters.selectedSuggestedReplies === 'true') {
            newSearchParams.set(SUGGESTED_REPLIES, searchParameters.selectedSuggestedReplies)
        } else {
            newSearchParams.delete(SUGGESTED_REPLIES)
        }

        if (searchParameters.selectedReplies.length > 0) {
            newSearchParams.set(REPLIES, searchParameters.selectedReplies)
        } else {
            newSearchParams.delete(REPLIES)
        }

        if (searchParameters.adOrOrganic) {
            newSearchParams.set(ADS_OR_ORGANIC_QUERY_PARAM, searchParameters.adOrOrganic)
        } else {
            newSearchParams.delete(ADS_OR_ORGANIC_QUERY_PARAM)
        }

        if (searchParameters.readReceipt) {
            newSearchParams.set(READ_RECEIPT_QUERY_PARAM, searchParameters.readReceipt)
        } else {
            newSearchParams.delete(READ_RECEIPT_QUERY_PARAM)
        }

        setSearchParams(newSearchParams)
    }

    return {
        searchParameters: {
            selectedAccountIds: accountIds,
            selectedTeams: teamIds,
            selectedSocialMediaServices: allSocialMediaServices.filter((service) =>
                socialMediaServiceIds.includes(service.id)
            ),
            timeDuration,
            startDateTime,
            endDateTime,
            keyword,
            moderation,

            selectedEmotions,
            selectedTopics,

            selectedSuggestedReplies: suggestedReplies,
            selectedReplies: replies,

            selectedGeneralClassifiers,

            selectedClassifiers: [
                ...selectedEmotions.map((emotion) => getClassifierSearchParam(emotion, EMOTION_THRESHOLD)),
                ...selectedTopics.map((topic) => getClassifierSearchParam(topic, TOPICS_THRESHOLD)),
                ...selectedGeneralClassifiers.map((classifier) => getClassifierSearchParam(classifier)),
            ],

            adOrOrganic,
            readReceipt,
            // Default value
            owned: true,
        },
        onSearchParameterChange: handleParameterChange,
        allSocialMediaServices,
        setAllSocialMediaServices,
    }
}
