import * as React from 'react'
import { classNames } from '../util/classNames'

type Props = {
    text: string | React.ReactNode
    primary: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    type: 'submit' | 'button'
    size?: 'small' | 'medium' | 'large'
    disabled?: boolean
}

function Button(props: Props) {
    const { text, primary, size, disabled } = props
    const baseClasses =
        'inline-flex items-center px-3 py-2 border border-transparent leading-4 rounded-md shadow focus:outline-none ring-1 focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 drop-shadow-sm hover:bg-gray-200 hover:drop-shadow-md focus:ring-primary-500 focus:ring-offset-primary-200 transition ease-in-out duration-150'
    const variableClasses = primary
        ? ` text-white bg-primary hover:bg-primary `
        : ` text-gray-950 ring-gray-300 hover:bg-gray-200`

    const disabledClasses = disabled
        ? 'cursor-not-allowed pointer-events-none opacity-50'
        : ''

    let sizeClasses = ''
    if (size === 'small') {
        sizeClasses = 'px-2.5 py-1.5 text-xs'
    } else if (size === 'medium') {
        sizeClasses = 'px-3 py-2 text-sm'
    } else if (size === 'large') {
        sizeClasses = 'px-6 py-3 text-base'
    }

    // We can't pass 'primary' as a prop to the button because it's not a valid HTML attribute,
    // but we want to be able to accept it so we can modify how we render the button (above). So
    // we're going to delete it from the props object before we pass it to the HTML button.
    // Passing 'props' to the button means we remain a general purpose HTML component (I consider
    // it best practice). If we didn't we'd be reducing our flexibility a lot. In this
    // case we want to make sure data-cy is passed down.
    const propsClone = (({ primary, ...keys }) => keys)(props)
    return (
        <button
            disabled={disabled}
            className={`${baseClasses} ${variableClasses} ${classNames(
                sizeClasses
            )} ${disabledClasses}`}
            {...propsClone}
        >
            {text}
        </button>
    )
}

Button.defaultProps = {
    type: 'button',
}

export default Button
