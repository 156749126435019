import { AxisBottom, AxisLeft, Orientation, TickFormatter } from '@visx/axis'
import { Brush } from '@visx/brush'
import BaseBrush from '@visx/brush/lib/BaseBrush'
import { Bounds } from '@visx/brush/lib/types'
import { curveMonotoneX } from '@visx/curve'
import { LinearGradient } from '@visx/gradient'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { DateValue } from '@visx/mock-data/lib/generators/genDateValue'
import { scaleLinear, scaleTime } from '@visx/scale'
import { AreaClosed } from '@visx/shape'
import { Tooltip, defaultStyles } from '@visx/tooltip'
import { extent, max } from '@visx/vendor/d3-array'
import { timeFormat } from '@visx/vendor/d3-time-format'
import { useEffect, useRef, useState } from 'react'
import { useGetLineGraphQuery } from '../../api/client'
import NoResults from '../content/NoResults'
import QueryFailed from '../content/QueryFailed'
import { SearchParameters } from '../content/search/ContentSearchBar'
import dayjs from 'dayjs'

type LineChartProps = {
    width: number
    height: number
    searchParameters: SearchParameters
    onSearchParameterChange: (searchParams: SearchParameters) => void
}

export default function LineChart(props: LineChartProps) {
    const { width, height, searchParameters, onSearchParameterChange } = props

    const [tooltipOpen, setToolTipOpen] = useState<boolean>(false)
    const [tooltipLeft, setToolTipLeft] = useState(0)
    const [tooltipTop, setToolTipTop] = useState(0)
    const [tooltipData, setToolTipData] = useState({
        date: new Date(),
        value: 0,
    })

    const timeDuration = searchParameters.timeDuration
        ? `${searchParameters.timeDuration.toISOString()}`
        : undefined

    const { data, loading, error } = useGetLineGraphQuery({
        variables: {
            params: {
                since: searchParameters.startDateTime,
                before: searchParameters.endDateTime,
                accountIds: searchParameters.selectedAccountIds,
                teamIds: searchParameters.selectedTeams,
                socialMediaServiceIds:
                    searchParameters.selectedSocialMediaServices.map(
                        (s) => s.id
                    ),
                containsText: searchParameters.keyword,
                moderationString: [searchParameters.moderation],
                classifierSearchParams: searchParameters.selectedClassifiers,
                timeDuration,
            },
        },
    })

    const tickFormat: TickFormatter<any> = (
        value: Date | number,
        i: number
    ) => {
        if (value instanceof Date) {
            if (value.getHours() === 0) {
                return timeFormat('%b %d')(value)
            }
        }
    }

    const handleTooltip = (
        event: React.MouseEvent<SVGCircleElement>,
        data: any
    ) => {
        setToolTipOpen(true)
        setToolTipLeft(event.clientX)
        setToolTipTop(event.clientY)
        setToolTipData({
            date: data.date,
            value: data.value,
        })
    }

    const lineHeight = height
    const tooltipStyle = {
        ...defaultStyles,
        backgroundColor: 'white',
        color: 'rgb(75 85 99)',
        padding: 12,
        rx: 6,
        fontSize: '15px',
    }

    // data accessors
    const getX = (d: DateValue) => d.date

    const getY = (d: DateValue) => d.value as number

    // const [peakDate, setPeakDate] = useState<Date>()

    const [mappedData, setMappedData] = useState<DateValue[]>([])

    let xScale = scaleTime<number>({
        domain: extent(mappedData, getX) as [Date, Date],
        range: [0, width - 120],
    })
    let yScale = scaleLinear<number>({
        domain: [0, max(mappedData, getY) as number],
        range: [lineHeight - 10, 0],
    })

    const brushRef = useRef<BaseBrush | null>(null)

    const onBrushChange = (domain: Bounds | null) => {
        if (!domain) return
        const { x0, x1 } = domain

        const newStartDate = dayjs(x0)
        const newEndDate = dayjs(x1)

        onSearchParameterChange({
            ...searchParameters,
            startDateTime: newStartDate,
            endDateTime: newEndDate,
        })

        // const dataCopy = data.filter((s) => {
        //     const x = getX(s).getTime()
        //     const y = getY(s)
        //     return x > x0 && x < x1 && y > y0 && y < y1
        // })
        // setFilteredData(dataCopy)
        // xScale = scaleTime<number>({
        //     domain: extent(filteredData, getX) as [Date, Date],
        // })
        // if (brushRef?.current) {
        //     brushRef.current.reset()
        // }
    }

    useEffect(() => {
        if (data && !loading) {
            const newData = data.getLineGraph.map((d) => {
                return {
                    date: new Date(d.date),
                    value: d.contentCount,
                }
            })

            setMappedData(newData)

            // const brushRef = useRef<BaseBrush | null>(null)

            // eslint-disable-next-line react-hooks/exhaustive-deps
            xScale = scaleTime<number>({
                domain: extent(newData, getX) as [Date, Date],
                range: [0, width - 120],
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
            yScale = scaleLinear<number>({
                domain: [0, max(newData, getY) as number],
                range: [lineHeight - 10, 0],
            })

            // setPeakDate(
            //     newData.find((d) => d.value === (max(newData, getY) as number))
            //         ?.date
            // )
        }
    }, [data, loading])

    const showData = mappedData.length > 0 && !loading
    const showLoader = !showData && loading
    const showError = !loading && error
    return (
        <div>
            {showData ? (
                <div>
                    <svg width={width + 50} height={height + 60}>
                        <LinearGradient
                            id={'gradient'}
                            from="#8ecae6"
                            to="#177F97"
                            fromOpacity={1}
                            toOpacity={0.3}
                        />
                        {/* <GradientLightgreenGreen id="gradient" /> */}
                        <rect
                            width={width - 120}
                            height={height}
                            fill="none"
                            rx={14}
                            ry={14}
                        />
                        <Group key={`lines`} top={10} left={100}>
                            <AreaClosed<DateValue>
                                curve={curveMonotoneX}
                                data={mappedData}
                                pointerEvents="none"
                                x={(d) => xScale(getX(d)) ?? 0}
                                y={(d) => yScale(getY(d)) ?? 0}
                                markerWidth={2}
                                stroke="#333"
                                strokeWidth={1}
                                strokeOpacity={0.2}
                                shapeRendering="geometricPrecision"
                                markerMid="url(#marker-circle)"
                                markerStart="url(#marker-circle)"
                                markerEnd="url(#marker-circle)"
                                fill={'url(#gradient)'}
                                yScale={yScale}
                                opacity={0.8}
                            />
                            <Brush
                                xScale={xScale}
                                yScale={yScale}
                                width={width}
                                height={height}
                                margin={{ left: 100 }}
                                handleSize={8}
                                innerRef={brushRef}
                                resizeTriggerAreas={['left', 'right']}
                                brushDirection="horizontal"
                                // initialBrushPosition={initialBrushPosition}
                                onBrushEnd={onBrushChange}
                                // onClick={() => setFilteredData(data)}
                                useWindowMoveEvents
                                // onClick={() => }
                            />
                            {mappedData!.map((d, j) => {
                                const point = (
                                    <g key={j}>
                                        <circle
                                            key={j}
                                            r={4}
                                            cx={xScale(getX(d))}
                                            cy={yScale(getY(d))}
                                            stroke="rgba(33,33,33,0)"
                                            fill="#333"
                                            opacity={0.6}
                                            pointerEvents="none"
                                        />
                                        <circle
                                            key={j + 'surround'}
                                            r={15}
                                            cx={xScale(getX(d))}
                                            cy={yScale(getY(d))}
                                            opacity={0}
                                            onMouseOver={(
                                                event: React.MouseEvent<SVGCircleElement>
                                            ) => handleTooltip(event, d)}
                                            onMouseLeave={() => {
                                                setToolTipOpen(false)
                                            }}
                                        />
                                    </g>
                                )
                                return point
                            })}
                            {/* <Annotation
                                x={xScale(peakDate!)}
                                y={yScale(max(mappedData!, getY)!)}
                                dx={xScale(peakDate!) - 60 > 100 ? -60 : 60}
                                dy={60}
                            >
                                <Connector />
                                <CircleSubject r={5} />
                                <Label
                                    title="Peak time"
                                    subtitle={`Date: ${peakDate?.toDateString()}, Count: ${max(
                                        mappedData!,
                                        getY
                                    )}`}
                                />
                            </Annotation> */}
                        </Group>
                        <AxisBottom
                            key={`axis`}
                            orientation={Orientation.bottom}
                            top={height}
                            left={100}
                            scale={xScale!}
                            tickFormat={tickFormat}
                            numTicks={
                                mappedData!.length > 10
                                    ? 10
                                    : mappedData!.length
                            }
                            tickLabelProps={{ fontSize: 15 }}
                            labelProps={{
                                fontSize: 100,
                                strokeWidth: 10,
                                stroke: '#fff',
                                paintOrder: 'stroke',
                                fontFamily: 'sans-serif',
                                textAnchor: 'middle',
                            }}
                        />
                        <AxisLeft
                            left={100}
                            scale={yScale}
                            tickLabelProps={{ fontSize: 15 }}
                            top={10}
                        />
                        <GridRows
                            scale={yScale}
                            width={width - 30}
                            left={30}
                            top={10}
                            height={lineHeight}
                            stroke="#e0e0e0"
                            opacity={0.2}
                        />
                    </svg>
                    {tooltipOpen ? (
                        <Tooltip
                            key={'vis-tooltip'}
                            left={tooltipLeft}
                            top={tooltipTop}
                            style={tooltipStyle}
                        >
                            <div>
                                <p>
                                    <strong
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        Date: {tooltipData.date.toDateString()}
                                    </strong>
                                </p>
                                <p>
                                    <strong
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        Count: {tooltipData.value}
                                    </strong>
                                </p>
                            </div>
                        </Tooltip>
                    ) : null}
                </div>
            ) : showError ? (
                <div className="ml-9 mr-9">
                    <QueryFailed errorMessage={error?.message} />
                </div>
            ) : showLoader ? (
                <svg
                    className="animate-pulse bg-gray-300 rounded-md m-4 "
                    width={width - 20}
                    height={height - 20}
                ></svg>
            ) : (
                <NoResults />
            )}
        </div>
    )
}
